<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <form v-on:submit.prevent="submitForm">
      <input type="text" v-model="form.text">
      <button>Submit</button>
    </form>
    <p>{{ response }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HelloWorld",
  data() {
    return {
      form: {
        text: ''
      },
      response: null
    }
  },
  props: {
    msg: String
  },
  methods: {
    submitForm() {
      let component = this;
      axios.post(process.env.VUE_APP_API_BASE_URL, this.form)
          .then((res) => {
            component.response = JSON.stringify(res.data)
          })
    },
  },
};
</script>
